<template>
<b-container fluid>
  <b-row align-v="center" class="branding-header">
    <b-col id="hero-image">
      <img src="@/assets/logo-branding.svg" />
    </b-col>
    <b-col align-self="end" md="auto" class="p-4">
      <span class="title">{{ T('Adherence System') }}</span>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="p-5">
      <b-card v-if="isValidRequestID" class="mx-auto" :header="T('Reset Password request')" style="width: 18rem">
        <b-card-body>
          <b-form-group
            :label="T('Type in your new password')"
            label-for="password">
            <b-input-group>
              <b-input-group-text slot="prepend">
                <i class="fa fa-lock"></i>
              </b-input-group-text>
              <b-form-input type="password" v-model="password" />
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="T('Confirm your password')"
            label-for="confirmPassword">
            <b-input-group>
              <b-input-group-text slot="prepend">
                <i class="fa fa-lock"></i>
              </b-input-group-text>
              <b-form-input type="password" v-model="$v.confirmPassword.$model" />
            </b-input-group>
            <validation-error :validation="$v.confirmPassword" />
          </b-form-group>
        </b-card-body>
        <template v-slot:footer>
          <div class="text-right">
            <b-btn variant="primary" @click="onSubmit">{{ T('Submit') }}</b-btn>
          </div>
        </template>
      </b-card>
      <b-alert v-else show variant="warning" class="text-center">
        <h4>{{ T('Expired password request link') }}</h4>
  <router-link to="/reset-password/request">{{ T('Request reset password link') }}</router-link>
</b-alert>
</b-col>
</b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required }  from 'vuelidate/lib/validators';
import ValidationError from '../components/ValidationError';
  export default {
    components: { ValidationError },
    props: {
      rid: {
        required: true
      },
    },
    data() {
      return {
        password: '',
        confirmPassword: '',
      };
    },
    validations: {
      confirmPassword: {
        required,
        match: function(value) {
          return value === this.password;
        }
      },
    },
    computed: {
      ...mapGetters(['isValidRequestID']),
    },
    methods: {
      ...mapActions(['validateRequestID']),
      onSubmit() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$store.dispatch('resetPassword', {id: this.rid, password: this.password})
          this.$v.$reset();
          this.$router.push({ name: 'login' });
        }
      }
    },
    created() {
      this.$store.dispatch('validateRequestID', this.rid)
    }
  }
</script>

<style lang="scss">
@import '@/themes/branding.scss';

.title {
    font-family: Verdana,Geneva,sans-serif; 
    color: white;
    font-size: 2rem;
    padding-right: 2rem;
}
</style>
