<template>
<b-container fluid>
  <b-row align-v="center" class="branding-header">
    <b-col id="hero-image">
      <img src="@/assets/logo-branding.svg" />
    </b-col>
    <b-col align-self="end" md="auto" class="p-4">
      <span class="title">{{ T('Adherence System')}}</span>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="p-5">
      <b-card class="mx-auto" :header="T('Reset Password')" style="width: 18rem">
        <b-card-body>
          <b-form-group
            :label="T('Please enter your Email address and we will send you a link via email to reset your password.')"
            label-for="userEmail">
            <b-input-group>
              <b-input-group-text slot="prepend">
                <i class="fa fa-user"></i>
              </b-input-group-text>
              <b-form-input type="email" v-model="$v.username.$model" />
            </b-input-group>
            <validation-error :validation="$v.username" />
          </b-form-group>
        </b-card-body>
        <template v-slot:footer>
          <div class="text-right">
            <b-btn variant="primary" @click="onSubmit">{{ T('Submit') }}</b-btn>
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import ValidationError from '../components/ValidationError';
  export default {
    components: { ValidationError },
    data() {
      return {
        username: '',
      };
    },
    validations: {
      username: {
        required,
        email,
      },
    },
    methods: {
      onSubmit() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$store.dispatch('submitPasswordResetRequest', this.username)
          this.$v.$reset();
          this.$router.push({ name: 'login' });
        }
      }
    },
  }
</script>

<style lang="scss">
@import '@/themes/branding.scss';

.title {
    font-family: Verdana,Geneva,sans-serif; 
    color: white;
    font-size: 2rem;
    padding-right: 2rem;
}
</style>
